import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { paymentClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { firstCapital } from 'src/utils/helper/membership';

import { DataCols, PageMaxWidth, Panel } from '@clubspark-react/clubspark-react-tools';

import AdminTable from '../admin-table/admin-table';
import Chip from '../chip/chip';
import EmptyState from '../empty-state/empty-state';
import PageHeader from '../page-header/page-header';
import { GET_VENUE_DEFAULT_ACCOUNT } from './payouts-report-queries';

const PayoutsReport: React.FC = () => {
  const { t } = useTranslation();

  const venueId = useOrgId();
  const { data, loading, error } = useQuery(GET_VENUE_DEFAULT_ACCOUNT, {
    variables: { venueId },
    client: paymentClient,
  });

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'paid':
        return 'green';
      case 'not paid':
        return 'red';
      default:
        return 'UNKNOWN';
    }
  };

  interface PayoutsReport {
    id: string;
    arrivalDate: number;
    description: string;
    amount: number;
    status: string;
  }

  const cols: DataCols<PayoutsReport> = useMemo(
    () => [
      {
        key: 'date',
        title: t('date'),
        getValue: (pr) => moment.unix(pr.arrivalDate).format('DD MMM YYYY'),
      },
      {
        key: 'description',
        title: t('description'),
        getValue: (pr) => t('tournament sanction fees'),
      },
      {
        key: 'amount',
        title: t('amount'),
        getValue: (pr) => `$${(pr.amount / 100).toFixed(2)}`,
      },
      {
        key: 'status',
        title: t('status'),
        getValue: (pr) => <Chip label={firstCapital(pr.status ?? '')} status={getStatusColor(pr.status ?? '')} />,
      },
    ],
    [t],
  );

  const handleOnClickTableRow = (t: PayoutsReport, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    navigate(`/tournaments/payouts-report/${t.id}`);
  };

  return (
    <PageMaxWidth>
      <PageHeader title={t('payout reports')} />
      <Panel>
        {loading || (data?.venueDefaultAccount?.payouts?.length ?? 0 > 0) ? (
          <>
            {/* <Grid container alignItems="center">
                <H4 spacing={{ margins: { xs: 'bottom' } }}>{t('payouts report')}</H4>
              </Grid> */}
            <AdminTable
              columns={cols}
              data={data?.venueDefaultAccount?.payouts}
              loading={loading}
              onRowClick={handleOnClickTableRow}
            />
          </>
        ) : (
          <EmptyState title={t('no payout reports')} icon="xl-document" />
        )}
      </Panel>
    </PageMaxWidth>
  );
};

export default PayoutsReport;
